import React, { FunctionComponent, useMemo } from 'react'
import { withApollo } from '../apollo/with-apollo-client'
import Head from 'next/head'
import * as CoreTypes from '../interfaces/core'
import { webVitalsTypes } from '../types/core'

import { ThirdPartyTags } from '../components/common/gtm-scripts/third-party'
import { GoogleTagManager } from '../components/common/gtm-scripts/head-script'
import GTMNoScript from '../components/common/gtm-scripts/gtm-no-script'
import { ApolloProvider } from '@apollo/client'
import { PageProvider } from '../utils/contexts/pageContext'

import PageContainer from '../components/containers/pageContainer'
import AppPage from '../components/appPage'
import '../styles/index.scss'
import PubSub, { PubSubStatic } from '../utils/class/PubSub'
import { initUtmDataStore } from '@grille/utils/functions/utm/utmData'
import BingAd from '@grille/components/common/bing-ad'
import { HeadScripts } from '@grille/components/common/scripts/head-scripts'
import { IAS_PUB_ID } from '@grille/constants/ads'
import { GeoLocationWrapper } from '@grille/app/wrappers/geo-location-wrapper'
import { generateUrl, isAdFixusEnabled } from '@grille/components/common/scripts/adfixus/helper'
import { ReactQueryProvider } from '@grille/app/wrappers/react-query-wrapper'

// Below function reports web vitals to GA
export function reportWebVitals({ id, name, label, value }: webVitalsTypes) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: 'webvitals',
      event_category: `grille ${label}`,
      event_value: Math.round(name === 'CLS' ? value * 1000 : value), // Must cast CLS to Int
      event_label: id,
      event_action: name
    })
  }
}

const GrilleApp: FunctionComponent<CoreTypes.GrilleAppProps> = ({
  Component,
  pageProps,
  err,
  apolloClient,
  nextServerRouter,
  ...rest
}) => {
  const pageStore = pageProps?.pageStore ?? {}
  const pagePubSub: PubSubStatic = useMemo(
    () => new PubSub({ allowMultiSubscribers: true }).export(),
    []
  )
  const initialPageStore = {
    ...pageStore,
    pageInfo: nextServerRouter,
    hideRoofrackAd: false,
    pagePubSub
  }
  const restProps = {
    initialPageStore,
    ...rest
  }

  //stores utm parameters in the url to the local storage for leads payload
  initUtmDataStore()

  const { standalone } = pageProps ?? {}

  if (standalone) {
    return <Component apolloClient={apolloClient} {...(pageProps ?? {})} />
  }

  /* include afxUrl only for non standalone pages */
  const afxUrl = generateUrl()

  return (
    <React.Fragment>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        {/**
         * Next Head component doesn't allow to render any component as Page Router renders head outside of react
         * We cannot use DriveFavicons component here
         * github issue: https://github.com/vercel/next.js/issues/8384#issuecomment-521975882
         */}
        <link rel='icon' href='/favicon-drive.ico' sizes='48x48' />
        <link rel='apple-touch-icon' href='/apple-touch-icon-180x180.png' />
        <link rel='manifest' href='/drive-icon.webmanifest' />
        <script
          id='script-ias'
          type='text/javascript'
          src='https://static.adsafeprotected.com/iasADX.js'
          async
          onLoad={() => window.__iasADX.start(IAS_PUB_ID)}
        />
        {/**
         * Next Head component doesn't allow to render any component as Page Router renders head outside of react
         * We cannot use AdfixusHeadScript component here
         * github issue: https://github.com/vercel/next.js/issues/8384#issuecomment-521975882
         */}
        {isAdFixusEnabled() || afxUrl ? (
          <>
            <link
              id='script-adfixus-loader'
              rel='preload'
              href={afxUrl as string}
              as='script'
            ></link>
            <script>var afx_start = Date.now();</script>
            <script id='script-adfixus' src={afxUrl as string} async />
          </>
        ) : null}
      </Head>
      <HeadScripts includeAdX={false} />
      <ApolloProvider client={apolloClient}>
        {/* should contain which sub menu element is inview */}
        <ReactQueryProvider>
          <PageProvider initialPageStore={initialPageStore}>
            <GeoLocationWrapper>
              <PageContainer>
                <AppPage Component={Component} err={err} pageProps={pageProps} {...restProps} />
              </PageContainer>
            </GeoLocationWrapper>
          </PageProvider>
        </ReactQueryProvider>
      </ApolloProvider>
      <ThirdPartyTags />
      <BingAd />
      <GoogleTagManager />
      <GTMNoScript />
    </React.Fragment>
  )
}

export default withApollo()(GrilleApp)
