'use client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren, useState } from 'react'

export function ReactQueryProvider({ children }: PropsWithChildren) {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          // 4 hours
          staleTime: 1000 * 60 * 60 * 4
        }
      }
    })
  )
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}
