import Router from 'next/router'
import fetch from 'cross-fetch'
import logger from '../../logger'
import { getRuntimeEnv } from '../get-runtime-env'
class PreviewClear {
  constructor(redirectUrl, options = { request: {}, response: {} }) {
    this.nextPublicDomain = getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')
    this.clearPreviewEndpoint = `${this.nextPublicDomain}/api/clear-preview`
    this.redirectUrl = redirectUrl
    this.isClient = typeof window !== 'undefined'
    this.req = options.request
    this.res = options.response
  }

  clear() {
    return this.isClient ? this.clearClient() : this.clearServer()
  }

  clearClient() {
    const router = Router.router
    if (router?.isPreview && !this.redirectUrl.includes('preview_id=')) {
      fetch(`${this.clearPreviewEndpoint}?slug=${this.redirectUrl}`, {
        method: 'GET',
        credentials: 'include'
      })
        .then(() => {
          window.location.href = this.redirectUrl
          return true
        })
        .catch((reason) => {
          logger.error(`preview/index.js clearClient - Error ${reason}`)
          return false
        })
    }
  }

  clearServer() {
    let cleared = false
    try {
      const searchParams = new URL(decodeURIComponent(this.redirectUrl), this.nextPublicDomain)
        .searchParams
      if (!searchParams.has('preview_id')) {
        const cookies = this.req?.cookies
        if (cookies?.__next_preview_data && !this.res?.finished) {
          const slug = this.req?.url
          if (!slug || slug === '') return false
          // added temporarily to debug for preview app
          logger.debug('Preview - Requesting url payload', this.req?.url, this.req?.headers)
          if (
            !slug.includes('favicon.ico') &&
            !slug.includes('_next/data') &&
            !slug.includes('graphql')
          ) {
            // sometimes browser request favion /favicon.ico which won't qualify for clearing previewData
            if (this.res?.writeHead) {
              this.res.writeHead(307, {
                Location: `${this.clearPreviewEndpoint}?slug=${slug}&source=server`
              })
              this.res.end()
              cleared = true
            }
          }
        }
      }
    } catch (error) {
      cleared = false
      logger.error(`preview/index.js clearServer - Error ${error}`)
    }
    return cleared
  }
}

export const previewClear = {
  clear: (redirectUrl, options = { request: {}, response: {} }) => {
    return new PreviewClear(redirectUrl, options).clear()
  }
}
