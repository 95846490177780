import { NUSED } from '@grille/types/listings'
import { formatPrice, getSingularOrPluralText } from './miscellaneous'
import isEmpty from 'lodash/isEmpty'

export * from './miscellaneous'

export const formatKms = (distance: number): string => {
  if (isNaN(distance)) {
    return ''
  }
  let kmsString = new Intl.NumberFormat().format(distance)

  return `${kmsString} ${getSingularOrPluralText(distance, 'km')}`
}

export const parseKms = (kmsString: string): number | null => {
  kmsString = kmsString
    .replace(/km[s]?/, '')
    .replace(/ /, '')
    .replace(/,/g, '')
    .replace(/\+/g, '')
  const parsedValue = parseInt(kmsString)
  return !isNaN(parsedValue) ? parsedValue : null
}

/**
 * Function to check if price is number and not 0
 * @param price {Number | Undefined}
 * @returns {boolean} Returns if price is valid number
 */
export const isValidPrice = (price: number | undefined | null): boolean => {
  return price !== null && isFinite(price ?? 0) && price !== 0
}

/**
 * Function to remove all characters from string except alphabet
 * @param  {NUSED | | null | string} string unclean string
 * @returns {string} Returns cleaned string
 */
export const cleanUpString = (string: NUSED | null | string): string => {
  return string?.replace(/[^a-zA-Z]/g, '') ?? ''
}

/**
 * Function to get client side javascript document cookie by name
 * @param  {name} string string
 * @returns {string | undefined} Returns cookie value by name or undefined if not found
 * @requires document
 */
export const getCookie = (name: string = ''): string | undefined => {
  if (isEmpty(name) || typeof window === 'undefined') return undefined

  const decodedCookies = decodeURIComponent(window.document.cookie)
  const cookiesArray = decodedCookies.split('; ')
  return cookiesArray.find((cookie) => cookie.startsWith(`${name}=`))?.split('=')[1]
}

/**
 * Function to remove all characters from string except alphabet
 * @param  {number | null } value Input number value
 * @returns {string} Returns NA/Rounded price format
 */
export const getNaAblePriceFormat = (value?: number | null) => {
  if (value) {
    return formatPrice(Math.round(value), true)
  }

  return 'N/A'
}
