import React from 'react'
import PropTypes from 'prop-types'
import { initOnContext, initializeApollo } from './client'
import { previewClear } from '../utils/functions/preview'
import App from 'next/app'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import mergeDeepWithKey from 'ramda/src/mergeDeepWithKey'
import equals from 'ramda/src/equals'
import logger from '../utils/logger'

const mergeSameKey = (k, l, r) => {
  if (isArray(r) && isArray(l)) {
    return r.filter((d) => l.every((s) => !equals(d, l)))
  } else {
    return { ...l, ...r }
  }
}

export const withApollo = () => (PageComponent) => {
  const withApollo = (props) => {
    let apolloClient = props.apolloClient
    let newProps = props
    const { apolloState = {}, pageProps = {} } = props || {}
    const { APOLLO_INITIAL_STATE = {} } = pageProps
    let newPageProps = pageProps
    if (!apolloClient) {
      // Happens on: next.js csr
      // Merge the existing cache into data passed from getStaticProps/getServerSideProps
      const apolloStates = isEmpty(APOLLO_INITIAL_STATE)
        ? apolloState
        : mergeDeepWithKey(mergeSameKey, APOLLO_INITIAL_STATE, apolloState)

      newPageProps = {
        ...pageProps
      }

      if (pageProps.APOLLO_INITIAL_STATE) {
        delete pageProps.APOLLO_INITIAL_STATE
      }

      newProps = {
        ...props,
        pageProps: newPageProps,
        apolloState: apolloStates
      }

      apolloClient = initializeApollo(apolloStates)
    }
    return <PageComponent {...newProps} apolloClient={apolloClient} />
  }

  withApollo.getInitialProps = async (ctx) => {
    const {
      ctx: { req, res }
    } = ctx
    const inAppContext = Boolean(ctx.ctx)
    let appProps = {}
    if (PageComponent.getInitialProps) {
      appProps = await PageComponent.getInitialProps(ctx)
    } else if (inAppContext) {
      appProps = await App.getInitialProps(ctx)
    }
    const { apolloClient } = initOnContext(ctx)

    const apollo = apolloClient || ctx.apolloClient

    if (typeof window === 'undefined') {
      // Run all graphql queries in the component tree
      // and extract the resulting data
      const { AppTree } = ctx
      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return appProps
      }

      try {
        // Import `@apollo/react-ssr` dynamically.
        // We don't want to have this in our client bundle.
        // eslint-disable-next-line
        const { getDataFromTree } = await require('@apollo/client/react/ssr')
        // Run all GraphQL queries
        await getDataFromTree(<AppTree {...appProps} apolloClient={apollo} />)
      } catch (error) {
        // Prevent Apollo Client GraphQL errors from crashing SSR.
        // Handle them in components via the data.error prop:
        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
        const { router } = ctx
        logger.error(
          `[App Error] - with-apollo-client.js - getDataFromTree [route:${router?.route}] [path:${router?.asPath}] ${error.message} ${error.stack}`
        )
      }
    }

    appProps = {
      ...appProps,
      nextServerRouter: {
        ...(ctx?.router ?? {})
      },
      // Extract query data from the Apollo store
      apolloState: apollo.cache.extract(),
      apolloClient: apolloClient
    }
    if (!req) {
      return { ...appProps }
    } else {
      return previewClear.clear(req?.url, { request: req, response: res })
        ? { ...appProps }
        : { ...appProps }
    }
  }
  withApollo.propTypes = {
    apolloState: PropTypes.object,
    apolloClient: PropTypes.object
  }

  return withApollo
}
