import { UTMDataType } from '../../../types/utmDataType'
import { setUtmDataFromUrl } from './setUtmData'
import { getUrlQueryParams, getUtmDriveCache, hasUtmData } from './utils'

export const UTM_LOCAL_STORE_KEY = 'UTM_LOCAL_STORE_KEY'
export const UTM_DATA_KEY = 'utm_data'
export const UTM_DATA_KEYS: (keyof UTMDataType)[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_channel'
]
export const UTM_DATA_RETENTION_IN_SECS = 604800 // 7 days

// initiates to store the utm parameters in local storage
export const initUtmDataStore = (): boolean => {
  const paramsQuery = getUrlQueryParams()
  return hasUtmData(paramsQuery) ? setUtmDataFromUrl(paramsQuery, getUtmDriveCache()) : false
}
