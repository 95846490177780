'use client'
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
/* eslint-disable max-lines */
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
const GamAdPath = getRuntimeEnv('NEXT_PUBLIC_GAM_AD_PATH') || '/5167826/Drive.com.au/'
const Env = getRuntimeEnv('NEXT_PUBLIC_DEPLOY_ENV')
import Script from 'next/script'
import { memo } from 'react'

export const areTaggingAdsDisabled = () => {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    return window.location.search.indexOf('__gamDAAT') > -1
  }
  return false
}

export const GoogleTagManager = memo(() => {
  if (areTaggingAdsDisabled()) {
    return null
  }
  let gtmUrl
  if (Env === 'prod') {
    gtmUrl =
      'https://www.googletagmanager.com/gtm.js?id=GTM-NXB4GPT&gtm_auth=CDQi8afk4naL6DOdxkEk4A&gtm_preview=env-1&gtm_cookies_win=x'
  } else if (Env === 'staging') {
    gtmUrl =
      'https://www.googletagmanager.com/gtm.js?id=GTM-NXB4GPT&gtm_auth=ma2HrRYocTLAFr2fV5bB3g&gtm_preview=env-4&gtm_cookies_win=x'
  } else {
    gtmUrl =
      'https://www.googletagmanager.com/gtm.js?id=GTM-NXB4GPT&gtm_auth=_Yk_ZK41H-dTVZvsG1rFhg&gtm_preview=env-3&gtm_cookies_win=x'
  }
  return (
    <Script
      id='script-gtm'
      src={gtmUrl}
      type='text/javascript'
      strategy='afterInteractive'
      onLoad={() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: 'gtm.js' })
      }}
    />
  )
})

export const getGtmConfig = (env = Env) => {
  if (env === 'prod') {
    return {
      gtmId: 'GTM-NXB4GPT',
      auth: 'CDQi8afk4naL6DOdxkEk4A',
      preview: 'env-1'
    }
  } else if (env === 'staging') {
    return {
      gtmId: 'GTM-NXB4GPT',
      auth: 'ma2HrRYocTLAFr2fV5bB3g',
      preview: 'env-4'
    }
  } else {
    return {
      gtmId: 'GTM-NXB4GPT',
      auth: '_Yk_ZK41H-dTVZvsG1rFhg',
      preview: 'env-3'
    }
  }
}

export const DataLayer = memo(function DataLayer({ isAppRoute = false }) {
  const html = {
    __html: `window.googletag = window.googletag || {cmd: []};
          window.dataLayer = window.dataLayer || [];
          window.pageDataLayer = window.pageDataLayer || {};
          window.pageTargeting = window.pageTargeting || {};
          window.gamData = {slots: {}, _gamDAAT: ${areTaggingAdsDisabled()}};
          window.gamData.slots = {
            "gam_oop_pos0" : {
              "targeting" : [["pos", "0"]],
              "sizes" : []
            },
            "gam_roofrack_pos1" : {
              "dimension" : [728,90],
              "targeting" : [["pos", "1"]],
              "reservedHeight": "90px",
              "isReservedHeight": true,
              "sizes" : [
                [[1300, 0], [[970, 250],[728, 90],[970, 90]]],
                [[1024, 0], [[970, 250],[728, 90],[970, 90]]],
                [[768, 0], [728, 90]],
                [[0, 0], [[320, 50],[300, 50]]]
              ]
            },
            "gam_roofrack_pos2" : {
              "dimension" : [728,90],
              "targeting" : [["pos", "2"]],
              "reservedHeight": "90px",
              "isReservedHeight": true,
              "sizes" : [
                [[1300, 0], [[970, 250],[728, 90],[970, 90]]],
                [[1024, 0], [[970, 250],[728, 90],[970, 90]]],
                [[0, 0], []]
              ]
            },
            "gam_roofrack_hs_pos1" : {
              "dimension" : [728,90],
              "targeting" : [["pos", "1"]],
              "reservedHeight": "90px",
              "isReservedHeight": true,
              "sizes" : [
                [[1300, 0], [[970, 250],[728, 90],[970, 90]]],
                [[1024, 0], [[970, 250],[728, 90],[970, 90]]],
                [[768, 0], [728, 90]],
                [[0, 0], [[320, 50],[300, 50]]]
              ]
            },
            "gam_roofrack_gallery_pos1" : {
              "dimension" : [320, 50],
              "targeting" : [["pos", "1"]],
              "reservedHeight": "50px",
              "isReservedHeight": true,
              "sizes" : [
                [[0, 0], [[320, 50]]]
              ]
            },
            "gam_inreads_pos2" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "2"]],
              "reservedHeight": "400px",
              "isReservedHeight": true,
              "heightAttribute": "minHeight",
              "sizes" : [
                [[768,0], [[728, 90]]],
                [[0,0], [[300, 250], [300, 400]]]
              ]
            },
            "gam_inreads_pos3" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "3"]],
              "reservedHeight": "300px",
              "isReservedHeight": true,
              "sizes" : [
                [[768, 0], [[728, 90],[1, 2],[550, 310]]],
                [[0, 0], [[1, 2],[350, 200], [320, 50],[320, 250]]]
              ]
            },
            "gam_inreads_pos4" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "4"]],
              "sizes" : [
                [[768, 0], [728, 90]],
                [[0, 0], [[300, 250], [371, 660]]]
              ]
            },
            "gam_inreads_pos5" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "5"]],
              "sizes" : [
                [[768, 0], [728, 90]],
                [[0, 0], [320, 50]]
              ]
            },
            "gam_inreads_pos6" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "6"]],
              "sizes" : [
                [[768, 0], [728, 90]],
                [[0, 0], [300, 250]]
              ]
            },
            "gam_inreads_home_pos5" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : ["pos", "5"],
              "sizes" : [
                [[1024, 0], [[970, 250],[728, 90]]],
                [[768, 0], [728, 90]],
                [[0, 0], [320, 50]]
              ]
            },
            // mobile mrec and desktop leaderboard
            "gam_inreads_CFS_pos2" : {
              "collapsible" : true,
              "dimension" : [300,250],
              "targeting" : ["pos", "2"],
              "sizes" : [
                [[1024, 0], [728, 90]],
                [[0, 0], [300, 250]]
              ]
            },
            // always leaderboard
            "gam_inreads_CFS_pos3" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : ["pos", "3"],
              "sizes" : [
                [[768, 0], [728, 90]],
                [[0, 0], [320, 50]]
              ]
            },
            "gam_gallery_pos1" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos2" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos3" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "3"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos4" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "4"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos5" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "5"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos6" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "6"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos7" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "7"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos8" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "8"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos9" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "9"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos10" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "10"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos11" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "11"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
            "gam_gallery_pos12" : {
              "collapsible" : true,
              "dimension" : [728,90],
              "targeting" : [["pos", "12"]],
              "sizes" : [
                [[768, 0], [728, 90]]
              ]
            },
             "gam_listing_posR1" : {
              "collapsible" : false,
              "dimension" : [300,250],
              "targeting" : [["pos", "r1"]],
              "reservedHeight": "250px",
              "isReservedHeight": true,
              "sizes" : [
                [[768, 0], [300, 250]]
              ]
            },

            "gam_annex_posR1" : {
              "collapsible" : false,
              "dimension" : [300,250],
              "targeting" : [["pos", "r1"]],
              "reservedHeight": "250px",
              "isReservedHeight": true,
              "sizes" : [
                [[768, 0], [[300, 600],[300, 250]]],
                [[0, 0], []]
              ]
            },
            "gam_annex_posR2" : {
              "collapsible" : false,
              "dimension" : [300, 250],
              "targeting" : [["pos", "r2"]],
              "reservedHeight": "250px",
              "isReservedHeight": true,
              "sizes" : [
                [[768, 0], [300, 250]],
                [[0, 0], []]
              ]
            },
            "gam_annex_posR3" : {
              "collapsible" : false,
              "dimension" : [300, 250],
              "targeting" : [["pos", "r3"]],
              "reservedHeight": "250px",
              "sizes" : [
                [[768, 0], [300, 250]],
                [[0, 0], []]
              ]
            },
            "gam_annex_posR4" : {
              "collapsible" : false,
              "dimension" : [300, 250],
              "targeting" : [["pos", "r4"]],
              "reservedHeight": "250px",
              "sizes" : [
                [[768, 0], [300, 250]],
                [[0, 0], []]
              ]
            },
            "gam_annex_posR5" : {
              "collapsible" : false,
              "dimension" : [300, 250],
              "targeting" : ["pos", "r5"],
              "sizes" : [
                [[768, 0], [300, 250]],
                [[0, 0], []]
              ]
            },
            "gam_annex_gallery_posR1" : {
              "collapsible" : false,
              "dimension" : [300,250],
              "targeting" : [["pos", "r1"]],
              "reservedHeight": "600px",
              "sizes" : [
                [[768, 0], [[300, 600]]],
                [[0, 0], []]
              ]
            },
            // Review CTA ad units
            "gam_cta_button_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["cta_button"]]],
              "sizes" : []
            },
            "gam_cta_button_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["cta_button"]]],
              "sizes" : []
            },
            "gam_cta_button_pos3" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "3"],["native", ["cta_button"]]],
              "sizes" : []
            },
            "gam_stickydesktop_split_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["stickydesktop_split"]]],
              "sizes" : []
            },
            "gam_stickydesktop_split_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["stickydesktop_split"]]],
              "sizes" : []
            },
            "gam_stickydesktop_split_pos3" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "3"],["native", ["stickydesktop_split"]]],
              "sizes" : []
            },
            "gam_textstickydesk_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["stickydesktop"]]],
              "sizes" : [
                [[1024, 0], ["fluid"]],
                [[0, 0], []]
              ]
            },
            "gam_stickymobile_split_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["stickymobile_split"]]],
              "sizes" : []
            },
            "gam_stickymobile_split_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["stickymobile_split"]]],
              "sizes" : []
            },
            "gam_stickymobile_split_pos3" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "3"],["native", ["stickymobile_split"]]],
              "sizes" : []
            },
            "gam_textstickymobile_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["stickymobile"]]],
              "sizes" : [
                [[1024, 0], []],
                [[0, 0], ["fluid"]]
              ]
            },
            "gam_fixedresponsive_split_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_fixedresponsive_split_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_fixedresponsive_split_pos3" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "3"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_fixedresponsive_split_pos4" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "4"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_fixedresponsive_split_pos5" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "5"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_fixedresponsive_split_pos6" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "6"],["native", ["fixedresponsive_split"]]],
              "sizes" : []
            },
            "gam_textfixed_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["fixedresponsive"]]],
              "sizes" : []
            },
            "gam_textfixed_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["fixedresponsive"]]],
              "sizes" : []
            },
            "gam_texttransact_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["transactional"]]],
              "sizes" : []
            },
            "gam_texttransact_pos2" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "2"],["native", ["transactional"]]],
              "sizes" : []
            },
            "gam_texttransact_pos3" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "3"],["native", ["transactional"]]],
              "sizes" : []
            },
            "gam_featsidebar_pos1" : {
              "collapsible" : true,
              "dimension" : [1,5],
              "targeting" : [["pos", "1"]],
              "sizes" : []
              },
              "gam_featblock_pos1" : {
              "collapsible" : true,
              "dimension" : [1,4],
              "targeting" : [["pos", "1"]],
              "sizes" : []
              },
              "gam_featblock_pos2" : {
              "collapsible" : true,
              "dimension" : [1,4],
              "targeting" : [["pos", "2"]],
              "sizes" : []
            },
            "gam_feattile_pos1" : {
              "collapsible" : false,
              "dimension" : [1,6],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [[612, 343]]],
                [[0, 0], [[360, 202]]]
              ]
              },
              "gam_feattile_pos2" : {
              "collapsible" : false,
              "dimension" : [1,6],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [[612, 343]]],
                [[0, 0], [[360, 202]]]
              ]
            },
            "gam_feattile_pos3" : {
              "collapsible" : false,
              "dimension" : [1,6],
              "targeting" : [["pos", "3"]],
              "sizes" : [
                [[768, 0], [[612, 343]]],
                [[0, 0], [[360, 202]]]
              ]
            },
            "gam_btyblogo_pos1" : {
              "collapsible" : false,
              "dimension" : [1,11],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                  [[1023, 0], [[100, 70]]]
                ]
            },
            "gam_featblockHPcarsho_pos1" : {
              "collapsible" : false,
              "dimension" : [1,12],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                  [[1023, 0], [[320, 290]]]
                ]
            },
            "gam_desknavcomp_pos1" : {
              "collapsible" : false,
              "dimension" : [1,7],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[1024, 0], [272, 288]],
                [[0, 0], []]
              ]
            },
            "gam_desknavcomp_pos2" : {
              "collapsible" : false,
              "dimension" : [1,7],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[1024, 0], [272, 288]],
                [[0, 0], []]
              ]
            },
            "gam_desknavcfs_pos1" : {
              "collapsible" : false,
              "dimension" : [1,8],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[1024, 0], [272, 288]],
                [[0, 0], []]
              ]
            },
            "gam_desknavcfs_pos2" : {
              "collapsible" : false,
              "dimension" : [1,8],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[1024, 0], [272, 288]],
                [[0, 0], []]
              ]
            },
            "gam_featblockshownews_pos1" : {
              "collapsible" : false,
              "dimension" : [1,14],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshownews_pos2" : {
              "collapsible" : false,
              "dimension" : [1,14],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowrev_pos1" : {
              "collapsible" : false,
              "dimension" : [1,15],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowrev_pos2" : {
              "collapsible" : false,
              "dimension" : [1,15],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
                ]
            },
            "gam_featblockshowvideo_pos1" : {
              "collapsible" : false,
              "dimension" : [1,16],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowvideo_pos2" : {
              "collapsible" : false,
              "dimension" : [1,16],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowownrev_pos1" : {
              "collapsible" : false,
              "dimension" : [1,17],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowownrev_pos2" : {
              "collapsible" : false,
              "dimension" : [1,17],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowcaradv_pos1" : {
              "collapsible" : false,
              "dimension" : [1,18],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockshowcaradv_pos2" : {
              "collapsible" : false,
              "dimension" : [1,18],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[768, 0], [400, 392]],
                [[0, 0], [320, 344]]
              ]
            },
            "gam_featblockcars_pos1" : {
              "collapsible" : false,
              "dimension" : [1,10],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[1023, 0], [272, 288]]
              ]
            },
            "gam_featblockcars_pos2" : {
              "collapsible" : false,
              "dimension" : [1,10],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[1023, 0], [272, 288]]
              ]
            },
            "gam_featblockcars_pos3" : {
              "collapsible" : false,
              "dimension" : [1,10],
              "targeting" : [["pos", "3"]],
              "sizes" : [
                [[1023, 0], [272, 288]]
              ]
            },
            "gam_featblockcars_pos4" : {
              "collapsible" : false,
              "dimension" : [1,10],
              "targeting" : [["pos", "4"]],
              "sizes" : [
                [[1023, 0], [272, 288]]
              ]
            },
            "gam_featblockvoucher_pos1" : {
              "collapsible" : true,
              "dimension" : [320,346],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[768, 0], [822, 190]],
                [[0, 0], [320, 346]]
              ]
            },
            "gam_curatedtile_pos1" : {
              "collapsible" : false,
              "dimension" : [1,16],
              "targeting" : [["pos", "1"]],
              "sizes" : [
                [[0, 0], [[612, 343]]],
              ]
            },
            "gam_curatedtile_pos2" : {
              "collapsible" : false,
              "dimension" : [1,16],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[0, 0], [[612, 343]]],
              ]
            },
            "gam_curatedtile_pos3" : {
              "collapsible" : false,
              "dimension" : [1,16],
              "targeting" : [["pos", "3"]],
              "sizes" : [
                [[0, 0], [[612, 343]]],
              ]
            },
            "gam_ad_shorts_pos2" : {
              "collapsible" : false,
              "dimension" : [1,17],
              "targeting" : [["pos", "2"]],
              "sizes" : [
                [[0, 0], [[279, 496]]],
              ]
            },
            "gam_sponsored_content_pos1" : {
              "collapsible" : true,
              "dimension" : ["fluid"],
              "targeting" : [["pos", "1"],["native", ["sponsored_content"]]],
              "sizes" : []
            },
        };
        ["gam_inreads_pos7", "gam_inreads_pos8", "gam_inreads_pos9", "gam_inreads_pos10",
          "gam_inreads_pos11", "gam_inreads_pos12", ]
          .forEach((k) => {
          window.gamData.slots[k] = window.gamData.slots["gam_inreads_pos6"];
          }
        );
        ["gam_inreads_CFS_pos6", "gam_inreads_CFS_pos7"]
          .forEach((k) => {
          window.gamData.slots[k] = window.gamData.slots["gam_inreads_CFS_pos2"];
          }
        );
        ["gam_inreads_CFS_pos4", "gam_inreads_CFS_pos5"]
          .forEach((k) => {
          window.gamData.slots[k] = window.gamData.slots["gam_inreads_CFS_pos3"];
          }
        );
 

        window.gamData.gamAdUnitPath = "${GamAdPath}";
        window.dataLayer.push({event: "custom.gamInitialised"});
        `
  }

  return isAppRoute ? (
    <Script
      id='script-datalayer'
      type='text/javascript'
      strategy='beforeInteractive'
      dangerouslySetInnerHTML={html}
    />
  ) : (
    <script id='script-datalayer' type='text/javascript' dangerouslySetInnerHTML={html} />
  )
})
