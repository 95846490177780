import { FunctionComponent, useContext, useEffect, useState } from 'react'
import styles from './notificationBanner.module.scss'
import cx from 'classnames'
import useWindowWidth from '../../utils/hooks/use-window-width'
import isEmpty from 'lodash/isEmpty'
import CompareBucketEngine, {
  CompareBucketEngineStatic
} from '../../utils/class/CompareBucketEngine'
import { PageContext } from '../../utils/contexts/pageContext'
import { PubSubStatic } from '../../utils/class/PubSub'
import {
  NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS,
  NOTIFICATION_SUB_NAVIGATION_STICKY_STATUS
} from '../../constants/notification-banner'
import { NOTIFICATION_CHANNEL_ID } from '../../constants/compareBucket'

/**
 * - shows notification banner
 *  - mobile: always from top
 *  - desktop: under sticky navs
 * - currently listens to compare bucket only
 * - if there is a need to support multiple notification from various places,
 * a notification service should implemented separately to handle notification pubsub.
 */

const NotificationBanner: FunctionComponent<Props> = () => {
  const { windowWidth }: { windowWidth: number } = useWindowWidth()
  // using object to make sure every message is a new reference
  const [content, setContent] = useState<{ message: string }>({ message: '' })
  const [subNavSticky, setSubNavSticky] = useState<boolean>(false)
  const [mainNavScrollSticky, setMainNavScrollSticky] = useState<boolean>(false)

  const { pageStore } = useContext(PageContext)
  const pagePubSub: PubSubStatic | null = pageStore?.pagePubSub ?? null
  const adType: 'sticky' | 'fixed' | null = pageStore?.gam_oop_pos0?.img?.['data-adtype'] ?? null

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cbEngine: CompareBucketEngineStatic | null =
        CompareBucketEngine.getCurrentInstance()?.export() ?? null

      let timer: ReturnType<typeof setTimeout> | null = null

      cbEngine?.subscribeToNotification((message: string) => {
        // update the topOffset to ensure the position is correct, as intersection observe can miss detection
        setContent({ message })
        // keep showing notification and close if no click within 3 seconds
        // assumes all message never change, no change in animation.
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
          setContent({ message: '' })
        }, 3000)
      })

      if (windowWidth > 1023) {
        // if it is sticky then main nav is always on sticky.
        if (adType !== 'sticky') {
          pagePubSub?.subscribe(NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS, (status: boolean) => {
            setMainNavScrollSticky(status)
          })
        }
        pagePubSub?.subscribe(NOTIFICATION_SUB_NAVIGATION_STICKY_STATUS, (status: boolean) => {
          setSubNavSticky(status)
        })
      }
    }
    return () => {
      CompareBucketEngine.getCurrentInstance()?.export()?.unsubscribe(NOTIFICATION_CHANNEL_ID)
    }
  }, [adType, pagePubSub, windowWidth])

  return (
    <div
      className={cx(styles['drive-notification-banner'], {
        [cx(styles['drive-notification-banner--show'])]: !isEmpty(content.message),
        [cx(styles['drive-notification-banner--mainnav-sticky'])]: mainNavScrollSticky,
        [cx(styles['drive-notification-banner--mainnav-sticky-PTO'])]: adType == 'sticky',
        [cx(styles['drive-notification-banner--subnav-sticky'])]: subNavSticky,
        [cx(styles['drive-notification-banner--stacked-sticky'])]:
          (mainNavScrollSticky || adType == 'sticky') && subNavSticky
      })}
    >
      <p
        className={cx(styles['drive-notification-banner__content'], {
          [styles['drive-notification-banner__content--show']]: !isEmpty(content.message)
        })}
      >
        {content.message}
      </p>
    </div>
  )
}

export default NotificationBanner

type Props = {}
