'use client'
import React, { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
const NamedContexts = []
export class PageStore {
  constructor(initialPageStore = {}) {
    this.setPageStore(initialPageStore)
  }

  getPageStore() {
    return this.pageStore
  }

  setPageStore(data) {
    this.pageStore = data
  }

  addToPageStore(property, value) {
    const pageStore = this.getPageStore()
    pageStore[property] = value
    this.setPageStore(pageStore)
  }

  export() {
    return {
      getPageStore: () => this.getPageStore(),
      updatePageStore: (property, value) => this.addToPageStore(property, value),
      resetStore: (data) => this.setPageStore(data)
    }
  }
}

const globalPageStore = new PageStore({})

export const PageContext = createContext()
PageContext.displayName = 'PageContext'

export const PageContextUpdater = createContext()
PageContextUpdater.displayName = 'PageContextUpdater'

export const PageProvider = ({
  children,
  initialPageStore = {},
  includePreviousPageStore = false
}) => {
  const [pageStoreInstance, _] = useState(() => {
    const initialPageStoreData = includePreviousPageStore
      ? { ...globalPageStore.getPageStore(), ...initialPageStore }
      : { ...initialPageStore }
    globalPageStore.setPageStore({ ...initialPageStoreData })
    return globalPageStore.export()
  })

  const shouldUpdate = (property, value) => {
    const currentPageStore = pageStoreInstance.getPageStore()
    return !isEqual(currentPageStore[property], value)
  }

  const pageStoreHandles = {
    pageStore: pageStoreInstance.getPageStore(),
    updateStore: (property, value) => {
      if (shouldUpdate(property, value)) {
        pageStoreInstance.updatePageStore(property, value)
        setContextStore({
          pageStore: pageStoreInstance.getPageStore(),
          updateStore: pageStoreHandles.updateStore,
          resetStore: pageStoreHandles.resetStore
        })
      }
    },

    resetStore: (newState = {}) => {
      pageStoreInstance.resetStore(newState)
      setContextStore({
        initialPageStore,
        ...pageStoreHandles,
        pageStore: pageStoreInstance.getPageStore()
      })
    }
  }

  const [contextStore, setContextStore] = useState({ ...pageStoreHandles })

  return (
    <PageContext.Provider value={{ ...contextStore, initialPageStore }}>
      <PageContextUpdater.Provider value={setContextStore}>{children}</PageContextUpdater.Provider>
    </PageContext.Provider>
  )
}

export const useUpdatePageCtx = () => {
  const contextUpdater = useContext(PageContextUpdater)

  const updater = useCallback(
    (property, value) =>
      contextUpdater((prevState) => {
        if (prevState?.pageStore && !isEqual(prevState.pageStore[property], value)) {
          globalPageStore.addToPageStore(property, value)
          const newState = {}
          newState[property] = value

          return { ...prevState, pageStore: { ...prevState.pageStore, ...newState } }
        }
        return prevState
      }),
    [contextUpdater]
  )
  return { updateStore: updater }
}

export const NamedPageProvider = ({
  children,
  initialPageStore = {},
  contextName = 'defaultNamed'
}) => {
  const Context = find(NamedContexts, { context: { displayName: contextName } }) ?? {}

  if (isEmpty(Context)) {
    const NamedContext = createContext(contextName)
    const NamedContextUpdater = createContext(`${contextName}-updater`)
    NamedContext.displayName = contextName
    NamedContextUpdater.displayName = `${contextName}-updater`

    Context.context = NamedContext
    Context.state = initialPageStore
    Context.updater = NamedContextUpdater
    NamedContexts.push({
      ...Context
    })
  }

  const [namedState, setNamedState] = useState(() => {
    return !Context ? initialPageStore : Context.state
  })

  return (
    <Context.context.Provider value={{ store: namedState }}>
      <Context.updater.Provider value={setNamedState}>{children}</Context.updater.Provider>
    </Context.context.Provider>
  )
}

export const useNamedPageContext = (contextName = 'defaultNamed') => {
  const namedContext = find(NamedContexts, { context: { displayName: contextName } }) ?? null
  if (!namedContext) {
    throw new Error(`useNamedPageContext must be used within a ${contextName} provider`)
  }
  return useContext(namedContext.context)
}

export const useUpdateNamedCtx = (contextName = 'defaultNamed') => {
  const namedContextUpdater =
    find(NamedContexts, { updater: { displayName: `${contextName}-updater` } }) ?? null
  if (!namedContextUpdater) {
    throw new Error(`useNamedPageContext must be used within a ${contextName} provider`)
  }

  const updateContext = useContext(namedContextUpdater.updater)
  const updater = useCallback(
    (property, value) =>
      updateContext((prevState) => {
        if (!isEqual(prevState[property], value)) {
          const newState = {}
          newState[property] = value
          return { ...prevState, ...newState }
        }
        return prevState
      }),
    [updateContext]
  )

  const reseter = useCallback(
    (value) =>
      updateContext((prevState) => {
        return { ...prevState, ...value }
      }),
    [updateContext]
  )
  return { updateStore: updater, resetStore: reseter }
}

PageProvider.propTypes = {
  children: PropTypes.node,
  initialPageStore: PropTypes.objectOf(PropTypes.any)
}
