/* eslint-disable max-lines */
import { GenericIconProps, IconList } from './types'
import { ImagineTransformObject } from '@caradvice/drive-imagine'
import { Icon } from './icon'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

export const IMAGES_HOSTNAME: string =
  (getRuntimeEnv('NEXT_PUBLIC_IMAGE_HOSTNAME') as string) || 'media.drive.com.au'
export const QualityFormatTransforms: ImagineTransformObject = {
  quality: 80,
  dpr: 2
}

const AllIcons: IconList = {
  HelpIcon: {
    assetId: 'cms/theme/icons_v3/Help.svg'
  },
  HomeCarouselPlayIcon: {
    assetId: 'cms/theme/icons_v3/HomeCarouselPlayIcon'
  },
  AddIcon: {
    assetId: 'cms/theme/icons_v3/Add'
  },
  AddInCircleIcon: {
    assetId: 'cms/theme/icons_v3/AddInCircle'
  },
  CircleAddIcon: {
    assetId: 'cms/theme/icons_v3/CirclePlus'
  },
  AdventureIcon: {
    assetId: 'cms/theme/icons_v3/Mountains'
  },
  AgeLogoStackedWhite: {
    assetId: 'cms/theme/icons_v3/TheAge',
    aspectRatio: 1.919,
    transforms: { color: 'rgb:ffffff' }
  },
  AncapSafetyIcon: {
    assetId: 'cms/theme/icons_v3/AncapSafety'
  },
  ArrowBackIcon: {
    assetId: 'cms/theme/icons_v3/ArrowLeft'
  },
  ArrowDownwardsIcon: {
    assetId: 'cms/theme/icons_v3/ArrowDown'
  },
  ArrowForwardIcon: {
    assetId: 'cms/theme/icons_v3/ArrowRight'
  },
  ArrowUpwardsIcon: {
    assetId: 'cms/theme/icons_v3/ArrowUp'
  },
  AssistantNavigationIcon: {
    assetId: 'cms/theme/icons_v3/AssistantNavigation'
  },
  AttachmentIcon: {
    assetId: 'cms/theme/icons_v3/Attachment'
  },
  Awards: {
    assetId: 'cms/theme/icons_v3/DCOTYCategoryWinner',
    aspectRatio: 0.7
  },
  BarChartIcon: {
    assetId: 'cms/theme/icons_v3/BarChart'
  },
  BatteryIcon: {
    assetId: 'cms/theme/icons_v3/Battery'
  },
  BackToTopIcon: {
    assetId: 'cms/theme/icons_v3/ArrowUp'
  },
  BinIcon: {
    assetId: 'cms/theme/icons_v3/Bin'
  },
  BluetoothIcon: {
    assetId: 'cms/theme/icons_v3/Bluetooth'
  },
  BtLogoNotaglineWhite: {
    assetId: 'cms/theme/icons_v3/BrisbaneTimes',
    aspectRatio: 6.875,
    transforms: { color: 'rgb:ffffff' }
  },
  CampingIcon: {
    assetId: 'cms/theme/icons_v3/Camping'
  },
  CallIcon: {
    assetId: 'cms/theme/icons_v3/Call'
  },
  CancelFillIcon: {
    assetId: 'cms/theme/icons_v3/CancelFill'
  },
  CaretTopRightIcon: {
    assetId: 'cms/theme/icons_v3/CarrotTopRight'
  },
  CarGenericIcon: {
    assetId: 'cms/theme/icons_v3/CarFrontOn'
  },
  CheckBoxOnIcon: {
    assetId: 'cms/theme/icons_v3/CheckBoxOn'
  },
  CheckBoxOffIcon: {
    assetId: 'cms/theme/icons_v3/CheckBoxOff'
  },
  CheckCircleOnIcon: {
    assetId: 'cms/theme/icons_v3/CheckCircleOn'
  },
  CheckCircleOffIcon: {
    assetId: 'cms/theme/icons_v3/CheckCircleOff'
  },
  CheckCircleOnFillIcon: {
    assetId: 'cms/theme/icons_v3/CheckCircleOnFill'
  },
  CheckCircleOffFillIcon: {
    assetId: 'cms/theme/icons_v3/CheckCricleFill'
  },
  CarIcon: {
    assetId: 'cms/theme/icons_v3/Car'
  },
  ChevronDown: {
    assetId: 'cms/theme/icons_v3/ChevronDown',
    aspectRatio: 1.6
  },
  ChevronLeftIcon: {
    assetId: 'cms/theme/icons_v3/ChevronLeft'
  },
  ChevronRightIcon: {
    assetId: 'cms/theme/icons_v3/ChevronRight'
  },
  ChevronUp: {
    assetId: 'cms/theme/icons_v3/ChevronUp',
    aspectRatio: 1.6
  },
  ChevronCircleDown: {
    assetId: 'cms/theme/icons_v3/ChevronCircleDown',
    aspectRatio: 1.6
  },
  ChevronCircleLeftIcon: {
    assetId: 'cms/theme/icons_v3/ChevronCircleLeft'
  },
  ChevronCircleRightIcon: {
    assetId: 'cms/theme/icons_v3/ChevronCircleRight'
  },
  ChevronCircleUpIcon: {
    assetId: 'cms/theme/icons_v3/ChevronCircleUp',
    aspectRatio: 1.6
  },
  ChevronDownSmallIcon: {
    assetId: 'cms/theme/icons_v3/ChevronDownSmall',
    aspectRatio: 1.6
  },
  ChevronLeftSmallIcon: {
    assetId: 'cms/theme/icons_v3/ChevronLeftSmall'
  },
  ChevronRightSmallIcon: {
    assetId: 'cms/theme/icons_v3/ChevronRightSmall'
  },
  ChevronUpSmallIcon: {
    assetId: 'cms/theme/icons_v3/ChevronUpSmall',
    aspectRatio: 1.6
  },
  CityIcon: {
    assetId: 'cms/theme/icons_v3/City'
  },
  ColourDotIcon: {
    assetId: 'cms/theme/icons_v3/ColourDot'
  },
  ColourRGBIcon: {
    assetId: 'cms/theme/icons_v3/ColourRGB'
  },
  ClearIcon: {
    assetId: 'cms/theme/icons_v3/Close'
  },
  CloseIcon: {
    assetId: 'cms/theme/icons_v3/Close'
  },
  CloseOutline: {
    assetId: 'cms/theme/icons_v3/Close'
  },
  CommentIcon: {
    assetId: 'cms/theme/icons_v3/Comment'
  },
  DcotyCategoryWinnerIcon: {
    assetId: 'cms/theme/icons_v3/DCOTYCategoryWinner'
  },
  DcotyFinalistIcon: {
    assetId: 'cms/theme/icons_v3/DCOTYFinalist'
  },
  DcotyWinnerIcon: {
    assetId: 'cms/theme/icons_v3/DCOTYWinner'
  },
  DealsDollarSignIcon: {
    assetId: 'cms/theme/icons_v3/Money'
  },
  DiscIcon: {
    assetId: 'cms/theme/icons_v3/Disc'
  },
  DownloadIcon: {
    assetId: 'cms/theme/icons_v3/Download'
  },
  DriveDIcon: {
    assetId: 'cms/theme/icons_v3/DriveD'
  },
  DrivetrainIcon: {
    assetId: 'cms/theme/icons_v3/DriveType'
  },
  DropIcon: {
    assetId: 'cms/theme/icons_v3/Drop'
  },
  DropFillHalfIcon: {
    assetId: 'cms/theme/icons_v3/DropFillHalf'
  },
  EcoIcon: {
    assetId: 'cms/theme/icons_v3/EnergyBolt'
  },
  ElectricCarIcon: {
    assetId: 'cms/theme/icons_v3/ElectricCar'
  },
  ElectricalServicesIcon: {
    assetId: 'cms/theme/icons_v3/ElectricalServices'
  },
  EnergyBoltIcon: {
    assetId: 'cms/theme/icons_v3/EnergyBolt'
  },
  ElectricEngineIcon: {
    assetId: 'cms/theme/icons_v3/ElectricEngine'
  },
  EvChargerIcon: {
    assetId: 'cms/theme/icons_v3/EvStation'
  },
  ElectricVehicleStationIcon: {
    assetId: 'cms/theme/icons_v3/EvStation'
  },
  EmailIcon: {
    assetId: 'cms/theme/icons_v3/Mail'
  },
  EngineIcon: {
    assetId: 'cms/theme/icons_v3/Engine'
  },
  EnginePowerIcon: {
    assetId: 'cms/theme/icons_v3/EnginePower'
  },
  ExpandLessIcon: {
    assetId: 'cms/theme/icons_v3/ChevronUpSmall'
  },
  ExpandMoreIcon: {
    assetId: 'cms/theme/icons_v3/ChevronDownSmall'
  },
  FacebookIcon: {
    assetId: 'cms/theme/icons_v3/Facebook'
  },
  FamilyIcon: {
    assetId: 'cms/theme/icons_v3/Family'
  },
  FilterClosedIcon: {
    assetId: 'cms/theme/icons_v3/FilterClosed',
    aspectRatio: 1.062
  },
  FilterOpenIcon: {
    assetId: 'cms/theme/icons_v3/FilterOpen',
    aspectRatio: 1.062
  },
  FilterIcon: {
    assetId: 'cms/theme/icons_v3/Tune.svg'
  },
  FindDealerIcon: {
    assetId: 'cms/theme/icons_v3/FindDealerIcon'
  },
  FireIcon: {
    assetId: 'cms/theme/icons_v3/Fire'
  },
  FirstPageIcon: {
    assetId: 'cms/theme/icons_v3/FirstPage'
  },
  FullScreenIcon: {
    assetId: 'cms/theme/icons_v3/FullScreen'
  },
  FullScreenExitIcon: {
    assetId: 'cms/theme/icons_v3/FullScreenExit'
  },
  FirstCarIcon: {
    assetId: 'cms/theme/icons_v3/PPlate'
  },
  FuelIcon: {
    assetId: 'cms/theme/icons_v3/PetrolStation'
  },
  GearStickIcon: {
    assetId: 'cms/theme/icons_v3/GearStick'
  },
  HeadPhonesIcon: {
    assetId: 'cms/theme/icons_v3/HeadPhones'
  },
  HeartIcon: {
    assetId: 'cms/theme/icons_v3/Heart'
  },
  HeartFillIcon: {
    assetId: 'cms/theme/icons_v3/HeartFill'
  },
  GreenTick: {
    assetId: 'cms/theme/icons_v3/Tick'
  },
  HomeIcon: {
    assetId: 'cms/theme/icons_v3/Home'
  },
  HistoryIcon: {
    assetId: 'cms/theme/icons_v3/History'
  },
  HybridPetrolIcon: {
    assetId: 'cms/theme/icons_v3/HybridPetrol'
  },
  FilledInfoIcon: {
    assetId: 'cms/theme/icons_v3/FilledInfo'
  },
  InfoIcon: {
    assetId: 'cms/theme/icons_v3/Info'
  },
  InsightsIcon: {
    assetId: 'cms/theme/icons/Insights',
    aspectRatio: 0.875
  },
  InstagramIcon: {
    assetId: 'cms/theme/icons_v3/Instagram'
  },
  KeyIcon: {
    assetId: 'cms/theme/icons_v3/Key'
  },
  LastPageIcon: {
    assetId: 'cms/theme/icons_v3/LastPage'
  },
  LineChartUpIcon: {
    assetId: 'cms/theme/icons_v3/LineChartUp'
  },
  LinkedinIcon: {
    assetId: 'cms/theme/icons_v3/LinkedIn'
  },
  LinkIcon: {
    assetId: 'cms/theme/icons/LinkIcon',
    transforms: { color: 'rgb:2960c5' }
  },
  LiveChat: {
    assetId: 'cms/theme/icons/LiveChat.svg'
  },
  LocationIcon: {
    assetId: 'cms/theme/icons_v3/Location'
  },
  LocationFilledIcon: {
    assetId: 'cms/theme/icons_v3/LocationFill'
  },
  LockIcon: {
    assetId: 'cms/theme/icons_v3/Lock'
  },
  MenuIcon: {
    assetId: 'cms/theme/icons/MenuIcon'
  },
  MinusIcon: {
    assetId: 'cms/theme/icons_v3/Minus',
    aspectRatio: 8.0
  },
  CircleMinusIcon: {
    assetId: 'cms/theme/icons_v3/CircleMinus',
    aspectRatio: 8.0
  },
  MoneyIcon: {
    assetId: 'cms/theme/icons_v3/Money',
    aspectRatio: 8.0
  },
  MountainsIcon: {
    assetId: 'cms/theme/icons_v3/Mountains',
    aspectRatio: 8.0
  },
  NinecomauLogoWhite: {
    assetId: 'cms/theme/icons_v3/NineDotComDotAu',
    aspectRatio: 3.076,
    transforms: { color: 'rgb:ffffff' }
  },
  Performance: {
    assetId: 'cms/theme/icons_v3/Performance'
  },
  PetrolStationIcon: {
    assetId: 'cms/theme/icons_v3/PetrolStation'
  },
  PhoneIcon: {
    assetId: 'cms/theme/icons/PhoneIcon'
  },
  PhotoIcon: {
    assetId: 'cms/theme/icons_v3/Picture'
  },
  PhotoFillIcon: {
    assetId: 'cms/theme/icons_v3/PictureFill'
  },
  PinIcon: {
    assetId: 'cms/theme/icons_v3/Location',
    aspectRatio: 0.9375
  },
  PlayIcon: {
    assetId: 'cms/theme/icons_v3/Play'
  },
  PlayIconRounded: {
    assetId: 'cms/theme/icons_v3/PlayCircle',
    transforms: { color: 'rgb:ffffff' }
  },
  PrestigeIcon: {
    assetId: 'cms/theme/icons_v3/Prestige'
  },
  Profile: {
    assetId: 'cms/theme/icons_v3/Profile'
  },
  QuestionMarkIcon: {
    assetId: 'cms/theme/icons_v3/QuestionMark'
  },
  RoadAddIcon: {
    assetId: 'cms/theme/icons_v3/RoadAdd'
  },
  RefreshIcon: {
    assetId: 'cms/theme/icons_v3/Refresh'
  },
  ReplyIcon: {
    assetId: 'cms/theme/icons_v3/Reply'
  },
  SavingsIcon: {
    assetId: 'cms/theme/icons_v3/Savings'
  },
  SchoolIcon: {
    assetId: 'cms/theme/icons_v3/School'
  },
  SearchIcon: {
    assetId: 'cms/theme/icons_v3/Search'
  },
  SearchRound: {
    assetId: 'cms/theme/icons/SearchRound'
  },
  SeatIcon: {
    assetId: 'cms/theme/icons_v3/Seat'
  },
  SellIcon: {
    assetId: 'cms/theme/icons_v3/Sell'
  },
  SendIcon: {
    assetId: 'cms/theme/icons_v3/Send'
  },
  SendFillIcon: {
    assetId: 'cms/theme/icons_v3/SendFill'
  },
  SettingsIcon: {
    assetId: 'cms/theme/icons_v3/Settings'
  },
  ShareIcon: {
    assetId: 'cms/theme/icons_v3/Share'
  },
  SmhLogoWhite: {
    assetId: 'cms/theme/icons_v3/TheSydneyMorningHerald',
    aspectRatio: 9.562,
    transforms: { color: 'rgb:ffffff' }
  },
  SortIcon: {
    assetId: 'cms/theme/icons_v3/Sort'
  },
  SportIcon: {
    assetId: 'cms/theme/icons_v3/Sport'
  },
  Svg9NowLogoWhite: {
    assetId: 'cms/theme/icons_v3/9Now',
    aspectRatio: 4.213,
    transforms: { color: 'rgb:ffffff' }
  },
  TagIcon: {
    assetId: 'cms/theme/icons_v3/Tag'
  },
  Terrain: {
    assetId: 'cms/theme/icons_v3/Mountains',
    aspectRatio: 1.5
  },
  ThumbnailsIcon: {
    assetId: 'cms/theme/icons_v3/Apps'
  },
  ThumbsUpIcon: {
    assetId: 'cms/theme/icons_v3/thumbUp'
  },
  TickIcon: {
    assetId: 'cms/theme/icons_v3/Tick'
  },
  TopFiveIcon: {
    assetId: 'cms/theme/icons_v3/Star5'
  },
  TransmissionIcon: {
    assetId: 'cms/theme/icons_v3/DriveType',
    aspectRatio: 1.375
  },
  TradieIcon: {
    assetId: 'cms/theme/icons_v3/Tradie',
    aspectRatio: 1.375
  },
  Trophy: {
    assetId: 'cms/theme/icons_v3/Trophy'
  },
  TwitterIcon: {
    assetId: 'cms/theme/icons_v3/X'
  },
  VanIcon: {
    assetId: 'cms/theme/icons_v3/Van'
  },
  VideoCameraIcon: {
    assetId: 'cms/theme/icons_v3/VideoCamera'
  },
  ViewDay: {
    assetId: 'cms/theme/icons_v3/ViewDay',
    aspectRatio: 1.428
  },
  WarrantyIcon: {
    assetId: 'cms/theme/icons_v3/VerifiedWarranty'
  },
  WatodayLogoNotaglineWhite: {
    assetId: 'cms/theme/icons_v3/WaToday',
    aspectRatio: 4.09,
    transforms: { color: 'rgb:ffffff' }
  },
  WaterIcon: {
    assetId: 'cms/theme/icons_v3/Water',
    aspectRatio: 4.09
  },
  WebsiteIcon: {
    assetId: 'cms/theme/icons_v3/Website',
    aspectRatio: 0.888
  },
  WifiIcon: {
    assetId: 'cms/theme/icons_v3/Wifi',
    aspectRatio: 0.888
  },
  WorkIcon: {
    assetId: 'cms/theme/icons_v3/Tradie'
  },
  YoutubeIcon: {
    assetId: 'cms/theme/icons_v3/YouTube'
  },
  OdometerIcon: {
    assetId: 'cms/theme/icons_v3/Speedo'
  },
  HamburgerMenuIconStraight: {
    assetId: 'cms/theme/icons_v3/BurgerMenu'
  },
  SpotifyIcon: {
    assetId: 'cms/theme/icons/SpotifyIconWhite'
  },
  StarIcon: {
    assetId: 'cms/theme/icons_v3/Star'
  },
  BackIcon: {
    assetId: 'cms/theme/icons_v3/ArrowLeft'
  },
  CancelIcon: {
    assetId: 'cms/theme/icons_v3/Cancel'
  },
  VolumeIcon: {
    assetId: 'cms/theme/icons_v3/Volume'
  },
  MuteIcon: {
    assetId: 'cms/theme/icons_v3/VolumeOff'
  },
  OptionsIcon: {
    assetId: 'cms/theme/icons_v3/Options'
  },
  MinimizeIcon: {
    assetId: 'cms/theme/icons_v3/Minus'
  },
  EmojiIcon: {
    assetId: 'cms/theme/icons_v3/SmileEmoji'
  },
  ZoomInIcon: {
    assetId: 'cms/theme/icons_v3/ZoomIn'
  },
  ZoomOutIcon: {
    assetId: 'cms/theme/icons_v3/ZoomOut'
  },
  ImageIcon: {
    assetId: 'cms/theme/icons_v3/Picture'
  },
  PinchZoomOutIcon: {
    assetId: 'cms/theme/icons_v3/PinchZoomOut'
  },
  BatteryChargingIcon: {
    assetId: 'cms/theme/icons_v3/Battery'
  },
  EVStationIcon: {
    assetId: 'cms/theme/icons_v3/EvStation'
  },
  BoltIcon: {
    assetId: 'cms/theme/icons_v3/EnergyBolt'
  },
  PaidWithCircleIcon: {
    assetId: 'cms/theme/icons_v3/DollarSign'
  },
  EnergyIcon: {
    assetId: 'cms/theme/icons_v3/EnergyBolt'
  },
  FamilyHubIcon: {
    assetId: 'cms/theme/icons_v3/Family'
  },
  KilometersIcon: {
    assetId: 'cms/theme/icons_v3/Speedo'
  },
  FuelTypePetrolIcon: {
    assetId: 'cms/theme/icons_v3/PetrolStation'
  },
  FuelTypeHybridIcon: {
    assetId: 'cms/theme/icons_v3/HybridPetrol'
  },
  FuelTypeElectricIcon: {
    assetId: 'cms/theme/icons_v3/EvCharger'
  },
  EngineSpecsIcon: {
    assetId: 'cms/theme/icons_v3/Engine'
  },
  EngineElectricIcon: {
    assetId: 'cms/theme/icons_v3/EngineElectric'
  },
  TransmissionSpecsIcon: {
    assetId: 'cms/theme/icons_v3/GearStick'
  },
  FuelEffeciencyIcon: {
    assetId: 'cms/theme/icons_v3/Drop'
  },
  DriveTypeIcon: {
    assetId: 'cms/theme/icons_v3/DriveType'
  },
  FactoryWarrantyIcon: {
    assetId: 'cms/theme/icons_v3/VerifiedWarranty'
  },
  SeatsIcon: {
    assetId: 'cms/theme/icons_v3/Seat'
  },
  BodyTypeHatchIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/hatchback'
  },
  BodyTypeConvertibleIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/convertible'
  },
  BodyTypeSuvIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/suv'
  },
  BodyTypeSedanIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/sedan'
  },
  BodyTypeUteIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/ute'
  },
  BodyTypePeopleMoverIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/people-mover'
  },
  BodyTypeVanIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/van'
  },
  BodyTypeWagonIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/wagon'
  },
  BodyTypeCabChasisIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/cab_chasis'
  },
  BodyTypeCoupeIcon: {
    assetId: 'cms/theme/icons/carfinder_bodytypes/coupe'
  },
  ExpandCircleLeftIcon: {
    assetId: 'cms/theme/icons_v3/ChevronCircleLeft'
  },
  ExpandCircleRightIcon: {
    assetId: 'cms/theme/icons_v3/ChevronCircleRight'
  },
  CheckCircleIcon: {
    assetId: 'cms/theme/icons_v3/CheckCircleOnFill'
  },
  LocationSpecsIcon: {
    assetId: 'cms/theme/icons_v3/Location.svg'
  },
  ChevronCircleUp: {
    assetId: 'cms/theme/icons_v3/ChevronCircleUp'
  },
  Colors: {
    assetId: '/cms/theme/icons_v3/ColourRGB'
  },
  StrattonFinanceLogo: {
    assetId: 'cms/theme/icons_v3/PoweredByStrattonFinance'
  },
  CallDealerIcon: {
    assetId: 'cms/theme/icons_v3/Operator'
  },
  PriceDown: {
    assetId: 'cms/theme/icons_v3/PriceDown'
  },
  DriveNused: {
    assetId: 'cms/theme/icons_v3/DriveNused'
  },
  NusedSmall: {
    assetId: 'cms/theme/icons_v3/NusedSmall'
  },
  NusedBig: {
    assetId: 'cms/theme/icons_v3/NusedBig'
  },
  TrendingDown: {
    assetId: 'cms/theme/icons_v3/TrendingDown'
  },
  DriveBodyTypeSpec: {
    assetId: 'cms/theme/icons_v3/Suv'
  },
  VerifiedWarrantyIcon: {
    assetId: 'cms/theme/icons_v3/VerifiedWarranty'
  },
  OlyLogo: {
    assetId: 'cms/theme/icons_v3/oly'
  },
  ShieldLock: {
    assetId: 'cms/theme/icons_v3/ShieldLock'
  },
  Calender: {
    assetId: 'cms/theme/icons_v3/Calender'
  }
}

const inPixels = (maybeRem: string | number): number | undefined => {
  if (maybeRem) {
    if (typeof maybeRem !== 'number' && maybeRem?.indexOf('rem') > -1) {
      const rems = parseFloat(maybeRem.replace('rem', ''))
      return rems * 16
    }
    return maybeRem as number
  }
  return undefined
}

export const scaleAndColor = (props: GenericIconProps): ImagineTransformObject => {
  const settings: ImagineTransformObject = {}
  if (props.color) {
    settings.color = props.color
  }

  if (props.width || props.height) {
    settings.resize = props.resize ?? 'auto'
    if (props.width) {
      settings.width = inPixels(props.width)
    }
    if (props.height) {
      settings.height = inPixels(props.height)
    }
  }

  return settings
}

export const fromAspectRatio = (
  aspectRatio: number = 1.0,
  from: string,
  isWidth: boolean
): number => {
  if (isWidth) {
    return Math.ceil(parseFloat(from) * aspectRatio)
  } else {
    return Math.ceil(parseFloat(from) / aspectRatio)
  }
}

export default Icon
export { Icon, AllIcons }
