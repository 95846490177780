import fetch from 'cross-fetch'
import { getRuntimeEnv } from './get-runtime-env'
import logger from '../logger'

type ApiResponse = {
  [key: string]: any
}

type Props = {
  path: string
}

export const getUrl = (path: string): string => {
  let nextPublicDomain: string = (getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL') as string) ?? ''

  nextPublicDomain =
    nextPublicDomain.includes('local-grille') && typeof window !== 'undefined'
      ? ''
      : nextPublicDomain

  return `${nextPublicDomain}/api/${path}`
}

export const fetchGrilleAPI = async ({ path }: Props): Promise<ApiResponse | undefined> => {
  const url = getUrl(path)

  try {
    const res = await fetch(url, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      logger.warn(`Grille API responded with non-OK status: ${res.status} ${res.statusText}`, {
        url,
        status: res.status
      })
      return undefined
    }

    const json = (await res.json()) as ApiResponse
    return json
  } catch (error) {
    logger.warn('Grille API request failed', {
      url,
      error: error instanceof Error ? error.message : String(error)
    })
    return undefined
  }
}
