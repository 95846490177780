import React, { FunctionComponent } from 'react'
import { useQuery } from '@apollo/client'

import NavBar from '../navbar'
import { GET_HAMBURGER_MENU, GET_HEADER_AND_FOOTER_MENUS } from '../../queries/get-menus'
import { GET_LISTING_MAKES_AVAILABLE } from '../../queries/cars-for-sale/listings/get-cars-for-sale-makes'
import { GET_TP_ALL_CURRENT_RELEASE_MAKES } from '../../queries/showrooms/makes/get-all-current-release-makes'
import { NextComponentType } from 'next'
import NotificationBanner from '../notificationBanner'
import CompareBucketEngine from '../../utils/class/CompareBucketEngine'

const AppPage: FunctionComponent<AppPageProps> = ({ Component, pageProps, err }) => {
  const { loading: loadingHeaderFooter } = useQuery(GET_HAMBURGER_MENU)
  const { loading: loadingHamburger } = useQuery(GET_HEADER_AND_FOOTER_MENUS)

  //for Find a car showroom search ssr
  useQuery(GET_TP_ALL_CURRENT_RELEASE_MAKES, {
    context: { tailpipe: true }
  })
  //for Find a car cfs search ssr
  useQuery(GET_LISTING_MAKES_AVAILABLE, {
    context: { listing: true }
  })
  if (typeof window !== 'undefined') {
    CompareBucketEngine.initialize()
  }

  if (loadingHeaderFooter || loadingHamburger) {
    return null
  }

  const pagePropsWithMenu = { ...pageProps }
  return (
    <>
      <NavBar mobilePersistStickyHeader={pagePropsWithMenu?.data?.mobilePersistStickyHeader} />
      <NotificationBanner />
      <Component {...pagePropsWithMenu} err={err} />
    </>
  )
}

type AppPageProps = {
  Component: NextComponentType
  pageProps: any
  err: any
  [key: string]: any
}

export default AppPage
