import { UTM_DATA_KEYS, UTM_DATA_RETENTION_IN_SECS, UTM_LOCAL_STORE_KEY } from './utmData'
import { UTMDataType, UTMDataTypeForLeads } from '../../../types/utmDataType'
import isEmpty from 'lodash/isEmpty'
import { IDriveCache, getDriveCache } from '@grille/utils/class/DriveCache'

export const filterUtmData = (
  utmData: Record<string, any>,
  utmKeys: string[] = UTM_DATA_KEYS
): UTMDataType => {
  return utmKeys.reduce((acc, key) => {
    return utmData?.[key] && !isEmpty(utmData?.[key]) && typeof utmData?.[key] === 'string'
      ? { ...acc, [key]: utmData?.[key] }
      : acc
  }, {})
}

export const convertLocalUtmDataForLeads = (localUtmData: UTMDataType): UTMDataTypeForLeads => {
  return Object.keys(localUtmData).reduce((acc, key) => {
    return { ...acc, [key.replace('utm_', '')]: localUtmData?.[key as keyof UTMDataType] }
  }, {})
}

export const getUtmDriveCache = (): IDriveCache => {
  return getDriveCache(UTM_LOCAL_STORE_KEY, UTM_DATA_RETENTION_IN_SECS)
}

export const hasUtmData = (params: any): boolean => {
  return !isEmpty(params) && typeof params === 'object' && !isEmpty(filterUtmData(params))
    ? true
    : false
}

export const getUrlQueryParams = (): Record<string, string> => {
  const paramsObject: Record<string, string> = {}

  if (typeof window !== 'object') {
    return paramsObject
  }

  const urlLink = window.location.href
  if (isEmpty(urlLink) || typeof urlLink !== 'string') {
    return paramsObject
  }

  const queryParams = new URL(urlLink)?.searchParams
  if (typeof queryParams !== 'object') {
    return paramsObject
  }

  queryParams.forEach((value, key) => {
    if (!isEmpty(value) && typeof value === 'string') {
      paramsObject[key] = value
    }
  })

  return paramsObject
}
