import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import React from 'react'
import logger from '../logger'
import { getRuntimeEnv } from './get-runtime-env'
import { getCookie } from './miscellaneous'

import { getIncludedAdFormats } from './getIncludedAdFormats'

const parseWpDataLayer = (wpDataLayer) => {
  if (isEmpty(wpDataLayer)) {
    return {}
  }
  if (isString(wpDataLayer)) {
    try {
      const wpDataLayerObject = JSON.parse(wpDataLayer)
      return isEmpty(wpDataLayerObject) ? {} : wpDataLayerObject[0]
    } catch (ex) {
      logger.error(`[WP DataLayer error] - ${ex}]`)
      return {}
    }
  } else {
    return isArray(wpDataLayer) ? wpDataLayer[0] : wpDataLayer
  }
}

const parseWpTargeting = (wpTargeting) => {
  if (isEmpty(wpTargeting)) {
    return {}
  }
  if (isString(wpTargeting)) {
    try {
      return JSON.parse(wpTargeting)
    } catch (ex) {
      logger.error(`[WP Targeting error] - Parsing ${ex}]`)
      return {}
    }
  } else {
    return isArray(wpTargeting) ? wpTargeting[0] : wpTargeting
  }
}

// https://support.google.com/admanager/answer/10020177
export const removeGAMUnsafeChars = (strOrArray, defaultValue) => {
  const cleanString = (str) => {
    // clean string except for hyphens
    if (typeof str === 'undefined' || str === null) {
      return str
    }
    return str.replace(/(?!-)\W/gi, '')
  }
  if (isArray(strOrArray)) {
    return strOrArray.map(cleanString)
  } else if (isString(strOrArray)) {
    return cleanString(strOrArray)
  }
  return defaultValue
}

const filterNulls = (arr) => {
  if (!arr || !isArray(arr)) {
    return arr
  }
  return arr.filter((item) => item !== null)
}

const gtmSetter = (wpDataLayer, wpTargeting, coorelator) => {
  const isClientSide = typeof window !== 'undefined'
  const currentCoorelator = isClientSide ? window.pageCoorelator : coorelator
  if (isClientSide && (!currentCoorelator || currentCoorelator !== coorelator)) {
    const dataLayer = parseWpDataLayer(wpDataLayer)
    const pageTargeting = parseWpTargeting(wpTargeting)
    pageTargeting.URI = window.location.pathname

    window.pageDataLayer = dataLayer
    const urlParams = new URLSearchParams(window.location.search)
    let adTest = urlParams.get('adtest')
    if (!adTest) {
      /* try getting from local Storage */
      try {
        window.localStorage?.getItem('adtest')
      } catch (error) {
        logger.error(`Error - getting localstorage of adtest}`, JSON.stringify({ error }))
      }
    }
    const adEnv = getRuntimeEnv('NEXT_PUBLIC_ADS_ENV')
    const windowWidth = window.innerWidth
    let includedAdFormats = getIncludedAdFormats({ windowWidth })
    const shouldTrackIsNused = [0, 1].includes(dataLayer.isNused)
    const targeting = {
      PageId: dataLayer.article?.postId ? `${dataLayer.article.postId}` : '',
      PageTitle: dataLayer.article?.title ?? '',
      PageCategory: dataLayer.taxonomy?.category ?? '',
      PageSection: dataLayer.taxonomy?.section ?? '',
      PageCategoryChild: dataLayer.taxonomy?.categorychild ?? '',
      PageContentType: dataLayer.taxonomy?.contenttype ?? 'edit',
      PostMustangType: filterNulls(dataLayer.taxonomy?.mustangtype) ?? [],
      PostDate: dataLayer.article?.postDate ? dataLayer.article?.postDate : '',
      PostModDate: dataLayer.article?.modDate ? dataLayer.article?.modDate : '',
      PostAuthor: dataLayer.article?.author ?? '',
      PostIsLegacy: dataLayer.article?.legacyContent ? `${dataLayer.article.legacyContent}` : '0',
      PostLegacyDrivId: dataLayer.article?.legacyDrivId ?? '',
      PostLegacyCaId: dataLayer.article?.legacyCaId ?? '',
      PostTags: dataLayer?.article?.tags ?? [],
      PostDcoty: dataLayer.mustangdcoty ?? [],
      // Each year value needs to be a string for GTM to accept it
      VariantYear:
        isArray(dataLayer.year) && !isEmpty(dataLayer.year)
          ? dataLayer.year.map((year) => `${year}`)
          : '',
      VariantMake: removeGAMUnsafeChars(dataLayer.make, []), // Replace characters not allowed by GAM
      VariantModel: removeGAMUnsafeChars(dataLayer.model, []),
      VariantVFacts: filterNulls(dataLayer.vfactssegment) ?? [],
      VariantFuelType: filterNulls(dataLayer?.fueltype) ?? [],
      VariantPriceBracket: filterNulls(dataLayer.pricebracket) ?? [],
      // Each avgrating value needs to be a string for GTM to accept it
      VariantAvgRating:
        isArray(dataLayer.avgrating) && !isEmpty(dataLayer.avgrating)
          ? dataLayer.avgrating.map((avgrating) => (avgrating ? `${avgrating}` : ''))
          : '',
      AdTest: isString(adTest) && !isEmpty(adTest) ? adTest.split(',') : ['false'],
      AdEnv: adEnv ?? 'staging',
      Env: adEnv ?? 'staging',
      AdAnnex: pageTargeting.ANX ? `${pageTargeting.ANX}` : '',
      AdBaseline: pageTargeting.BL ? `${pageTargeting.BL}` : '0',
      AdBaselineModel: pageTargeting.BLMD ? `${pageTargeting.BLMD}` : '0',
      AdBaselineMake: pageTargeting.BLMK ? `${pageTargeting.BLMK}` : '0',
      AdNegativeMake: pageTargeting.NEGMK ? `${pageTargeting.NEGMK}` : '0',
      AdNegativeAll: pageTargeting.NEGALL ? `${pageTargeting.NEGALL}` : '0',
      AdNegative: pageTargeting.NEG ? `${pageTargeting.NEG}` : '0',
      ScreenWidth: windowWidth,
      Skins: windowWidth < 1570 ? 'small' : 'large',
      IncludedAdFormats: includedAdFormats,
      Uri: pageTargeting.URI
    }

    if (shouldTrackIsNused) {
      targeting.VariantIsNused = dataLayer.isNused
    }

    window.pageTargeting = targeting
    const { VariantMake, VariantModel } = targeting
    if (
      VariantMake &&
      VariantModel &&
      isArray(VariantMake) &&
      isArray(VariantModel) &&
      VariantMake.length === VariantModel.length
    ) {
      targeting.VariantMakeModel = VariantMake.map(
        (make, index) => `${make}${VariantModel[index] || ''}`
      )
      pageTargeting.MKMD = targeting.VariantMakeModel
    }

    // We need to wait until MKMD is set before filtering VariantModel
    // otherwise the array lengths will
    targeting.VariantModel = filterNulls(targeting.VariantModel)
    window.adTargeting = targeting

    /* eslint-disable */
    window.googletag = window.googletag || { cmd: [] }
    window.gamData = window.gamData || {}
    window.gamData.adTargeting = targeting
    window.googletag.cmd.push(function () {
      if (!window.pageTargeting) {
        window.pageTargeting = {}
      }
      Object.keys(targeting).forEach((key) => {
        if (!isEmpty(targeting[key])) {
          googletag.pubads().setTargeting(key, targeting[key])
          window.pageTargeting[key] = targeting[key]
        }
      })

      /* include ppid and nuid to gtm providerId and targeting that we get from CDP
       * future reference, refer to https://driveau.atlassian.net/browse/CC-767 ticket
       */
      const nuidValue = getCookie('NUID')
      if (!isEmpty(nuidValue)) {
        window.googletag.pubads().setPublisherProvidedId(nuidValue)
        window.googletag.pubads().setTargeting('nuid', nuidValue)
      }
      googletag.pubads().setCentering(true)
      googletag.pubads().collapseEmptyDivs()

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 400,
        renderMarginPercent: 90,
        mobileScaling: 2.0
      })

      googletag.enableServices()
    })

    const dataLayerPayload = {
      env: targeting.AdEnv,
      taxonomy: {
        contenttype: targeting.PageContentType,
        section: targeting.PageSection,
        category: targeting.PageCategory,
        categorychild: targeting.PageCategoryChild,
        mustangtype: targeting.PostMustangType
      },
      year: targeting.VariantYear,
      make: targeting.VariantMake,
      model: targeting.VariantModel,
      vfactssegment: targeting.VariantVFacts,
      mustangtype: targeting.PostMustangType,
      mustangdcoty: targeting.PostDcoty,
      pricebracket: targeting.VariantPriceBracket,
      fueltype: targeting.VariantFuelType,
      avgrating: targeting.VariantAvgRating,
      pageVehicles: !isEmpty(dataLayer.pageVehicles)
        ? dataLayer.pageVehicles.map((v) => v['car'])
        : {},
      article: {
        title: targeting.PageTitle,
        postId: targeting.PageId,
        legacyContent: targeting.PostIsLegacy,
        legacyDrivId: targeting.PostLegacyDrivId,
        legacyCaId: targeting.PostLegacyCaId,
        postDate: targeting.PostDate,
        modDate: targeting.PostModDate,
        author: targeting.PostAuthor,
        tags: targeting.PostTags
      },
      screenWidth: targeting.ScreenWidth,
      skins: targeting.ScreenWidth < 1570 ? 'small' : 'large',
      includedAdFormats: targeting.IncludedAdFormats
    }
    if (shouldTrackIsNused) {
      dataLayerPayload.isNused = dataLayer.isNused
    }
    window.siteStorage = { pageTargetingData: dataLayerPayload } // used by the app in client side such as livechat for targetting

    window.dataLayer = window.dataLayer ?? []
    if (!window.pageviewTracked) {
      window.dataLayer.push({
        event: 'pageview',
        ...dataLayerPayload
      })
      window.pageviewTracked = true
    }
    window.pageCoorelator = coorelator
  }
}

const GTM = ({ dataLayer, targeting, coorelator }) => {
  return (
    <>
      <script
        id='page-gtmset'
        type='text/javascript'
        dangerouslySetInnerHTML={{ __html: gtmSetter(dataLayer, targeting, coorelator) }}
      />
    </>
  )
}

export const getAdPath = (adTargeting = {}) => {
  const sanitize = (str) => {
    if (!str) {
      return
    }
    return removeGAMUnsafeChars(str).replace(/\s+/gi, '')
  }

  const section = adTargeting.PageSection ? sanitize(adTargeting.PageSection) : null
  const category = adTargeting.PageCategory ? sanitize(adTargeting.PageCategory) : null
  const categoryChild = adTargeting.PageCategoryChild
    ? sanitize(adTargeting.PageCategoryChild)
    : null

  let pathName = isEmpty(section) ? 'home' : section
  pathName = !isEmpty(category) && category !== section ? `${pathName}/${category}` : pathName
  pathName =
    !isEmpty(categoryChild) && categoryChild !== category
      ? `${pathName}/${categoryChild}`
      : pathName

  return pathName
}

export const GTMComponent = React.memo(GTM)
