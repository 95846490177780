import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { IDriveCache } from '@grille/utils/class/DriveCache'
import { UTMDataType } from '../../../types/utmDataType'
import { filterUtmData } from './utils'
import { UTM_DATA_KEY } from './utmData'

export const setUtmDataFromUrl = (params: any, utmDriveCache: IDriveCache): boolean => {
  if (isEmpty(params) || typeof params !== 'object') {
    return false
  }

  const urlUtmData: UTMDataType = filterUtmData(params)
  if (isEmpty(urlUtmData)) {
    return false
  }

  return setUtmDataByParams(urlUtmData, utmDriveCache)
}

export const setUtmDataByParams = (params: UTMDataType, utmDriveCache: IDriveCache): boolean => {
  const localUtmData = utmDriveCache?.get(UTM_DATA_KEY)
  if (isEqual(localUtmData, params)) {
    return false
  }

  utmDriveCache.set(UTM_DATA_KEY, params, true)

  return true
}
