import { NetworkStatus, useQuery, ApolloError } from '@apollo/client'
import { FunctionComponent } from 'react'
import NavBarWithProps, { TMenuResponse } from '../navbar-with-props'
import { GET_HEADER_AND_FOOTER_MENUS, GET_HAMBURGER_MENU } from '@grille/queries/get-menus'
import isEmpty from 'lodash/isEmpty'
import Error from '../../common/error'

export const LegacyNavBar: FunctionComponent<NavBarProps> = ({
  mobilePersistStickyHeader = false
}) => {
  const headFooterMenusResponse: queryResponse = useQuery(GET_HEADER_AND_FOOTER_MENUS)
  const hamburgerMenusResponse: queryResponse = useQuery(GET_HAMBURGER_MENU)

  const headerFooterMenusStatus: { code: number | boolean; message: string } = {
    code: headFooterMenusResponse?.networkStatus === NetworkStatus.error ? 400 : 200,
    message: headFooterMenusResponse?.networkStatus === NetworkStatus.error ? 'error' : 'OK'
  }

  const hamburgerMenuStatus: { code: number | boolean; message: string } = {
    code: hamburgerMenusResponse?.networkStatus === NetworkStatus.error ? 400 : 200,
    message: hamburgerMenusResponse?.networkStatus === NetworkStatus.error ? 'error' : 'OK'
  }

  const { headerMenus } = headFooterMenusResponse?.data ?? {}
  const { hamburgerMenus } = hamburgerMenusResponse?.data ?? {}

  if (!isEmpty(headFooterMenusResponse?.error) && !isEmpty(hamburgerMenusResponse?.error)) {
    return (
      <>
        <Error status={headerFooterMenusStatus} children={undefined} />
        <Error status={hamburgerMenuStatus} children={undefined} />
      </>
    )
  }

  return (
    <NavBarWithProps
      mobilePersistStickyHeader={mobilePersistStickyHeader}
      hamburgerMenus={hamburgerMenus as TMenuResponse}
      headerMenus={headerMenus as TMenuResponse}
    />
  )
}

export default LegacyNavBar

export type Edge = {
  [key: string]: any
}

type queryResponse = {
  data?: {
    headerMenus: MenusResponse
    hamburgerMenus: MenusResponse
  }
  error?: ApolloError
  networkStatus?: NetworkStatus
}

type MenusResponse = {
  data?: {
    edges: Edge[]
  }
  [key: string]: any
}

type NavBarProps = {
  mobilePersistStickyHeader?: boolean
}
