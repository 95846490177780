import { FunctionComponent } from 'react'
import LegacyNavBar from './navbar-items/legacy-navbar'

const NavBar: FunctionComponent<NavBarProps> = ({ mobilePersistStickyHeader = false }) => {
  return <LegacyNavBar mobilePersistStickyHeader={mobilePersistStickyHeader} />
}

export default NavBar

type NavBarProps = {
  mobilePersistStickyHeader?: boolean
}
